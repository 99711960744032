module.exports = [{
      plugin: require('../../../shared/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pointless Corp","short_name":"Pointless Corp","start_url":"/","background_color":"#f5f5f5","theme_color":"#f5f5f5","display":"standalone","icon":"src/images/icons/icon.png","include_favicon":false},
    },{
      plugin: require('../../../shared/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
